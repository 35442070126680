import * as CookieConsent from "vanilla-cookieconsent";

const optInVideoService = function (serviceName = '') {
    window.dispatchEvent(new CustomEvent('cookieconsent-optinvideoservice', { detail: { service: serviceName } }))
    localStorage.setItem(`video_optin_${serviceName}`, JSON.stringify(true))
}
const optOutVideoService = function (serviceName = '') {
    window.dispatchEvent(new CustomEvent('cookieconsent-optoutvideoservice', { detail: { service: serviceName } }))
    localStorage.removeItem(`video_optin_${serviceName}`)
}

const cc = CookieConsent;

cc.run({

    mode: 'opt-in',
    revision: 4, // 2023-11-28

    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'bottom center',
        }
    },

    onConsent: function (test) {
        console.log(test)
        // consent was given
    },

    onChange: function (test) {
        console.log(test)
        // user changed his/her preferences
    },

    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {
            enabled: false,
            readOnly: false,

            services: {
                matomo: {
                    label: 'Matomo',
                },
                facebook_pixel: {
                    label: 'Facebook-Pixel',
                },
            },

            // Delete specific cookies when the user opts-out of this category
            autoClear: {
                cookies: [
                    // Google Analytics
                    {
                        name: /^_ga/,   // regex: match all cookies starting with '_ga'
                    },
                    {
                        name: '_gid',   // string: exact cookie name
                    },
                    // Facebook Pixel Cookies
                    {
                        name: 'xs',
                    },
                    {
                        name: 'presence',
                    },
                    {
                        name: 'oo',
                    },
                    {
                        name: 'usida',
                    },
                    {
                        name: 'dpr',
                    },
                    {
                        name: 'sb',
                    },
                    {
                        name: 'm_pixel_ratio',
                    },
                    {
                        name: 'c_user',
                    },
                    {
                        name: 'wd',
                    },
                    {
                        name: 'datr',
                    },
                    {
                        name: 'f',
                    },
                    {
                        name: '_fbp',
                    },
                    // Vimeo Cookies
                    {
                        name: 'player'
                    },
                    {
                        name: 'vuid'
                    },
                    {
                        name: '__cf_bm'
                    }
                ],
                reloadPage: true,
            }
        },
        external_media: {
            enabled: false,
            readOnly: false,

            services: {
                matterport: {
                    label: 'Matterport',
                    onAccept: () => optInVideoService('matterport'),
                    onReject: () => optOutVideoService('matterport'),
                },
                soundcloud: {
                    label: 'Soundcloud',
                    onAccept: () => optInVideoService('soundcloud'),
                    onReject: () => optOutVideoService('soundcloud'),
                },
                vimeo: {
                    label: 'Vimeo',
                    onAccept: () => optInVideoService('vimeo'),
                    onReject: () => optOutVideoService('vimeo'),
                },
                youtube: {
                    label: 'Youtube',
                    onAccept: () => optInVideoService('youtube'),
                    onReject: () => optOutVideoService('youtube'),
                },
                openstreetmap: {
                    label: 'OpenStreetMap',
                    onAccept: () => optInVideoService('openstreetmap'),
                    onReject: () => optOutVideoService('openstreetmap'),
                },
            },
        }
    },

    language: {
        default: 'de',

        translations: {
            de: window.cookieconsent_lang
        }
    }
});

window.cc = cc;